import React, { Fragment } from "react";
import {
  CREATE,
  fetchEnd,
  fetchStart,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { useForm } from "react-final-form";
import { Typography, Card, makeStyles, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import dataProvider from "../../Providers/dataProvider";

const Styles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
  },
  itemLeft: {
    width: "55%",
  },
  itemRight: {
    alignItems: "flex-end",
    padding: "11px",
    paddingTop: "40px",
    width: "400px",
  },
  contractButton: {
    margin: "10px",
    padding: "10px",
    width: "94%",
  },
  title: {
    margin: "10px 0px 0 7px",
  },
  divider: {
    backgroundColor: "#979797",
    marginLeft: "100px",
    marginRight: "100px",
    width: "2px",
  },
  CenterLoading: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "65px",
  },
}));

const PartnerCreate = ({ ...props }) => {
  const classes = Styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const history = useHistory();

  let submitQC;
  const SubmitExtractor = ({ children }) => {
    submitQC = useForm().submit;
    return children;
  };

  const handleSave = () => {
    submitQC();
  };

  const handleSubmit = (value) => {
    const { fetchStart, fetchEnd } = props;

    if (Number(value.userId) === value.userToAssociateId) {
      notify(`Los socios tienen que ser diferente`, "error", 6000);
    } else {
      fetchStart();
      dataProvider(CREATE, "users/associate", {
        data: { ...value },
      })
        .then(({ data }) => {
          notify(`Socio asociado exitosamente!`, "info");
          history.push("/users");
        })
        .catch(() => {
          notify(`Ha ocurrido un error`, "error");
        })
        .finally(() => {
          fetchEnd();
          refresh();
        });
    }
  };

  const ActionsToolbar = () => (
    <Toolbar>
      <SaveButton onClick={handleSave} handleSubmitWithRedirect={() => {}} />
    </Toolbar>
  );

  const Title = ({ children }) => (
    <Typography
      component="h2"
      variant="h3"
      color="textSecondary"
      className={classes.title}
    >
      {children}
    </Typography>
  );

  const id = props.location.search.split("=")[1];

  return (
    <Fragment>
      <div className={classes.root}>
        <Card className={classes.itemLeft}>
          <Title>Asociar socios</Title>
          <SimpleForm
            resource="loan-applications"
            save={handleSubmit}
            toolbar={<ActionsToolbar />}
            {...props}
          >
            <SubmitExtractor>
              <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                  <ReferenceInput
                    reference="users"
                    variant="outlined"
                    source="userToAssociateId"
                    label={translate("resources.users.fields.child")}
                    fullWidth
                    validate={required()}
                    formClassName={classes.width50}
                  >
                    <SelectInput optionText="person.name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} md={2}>
                  <div style={{ marginTop: "8px", textAlign: "center" }}>
                    <ArrowRightAltIcon fontSize="large" />
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <ReferenceInput
                    reference="users"
                    source="userId"
                    variant="outlined"
                    label={translate("resources.users.fields.parent")}
                    fullWidth
                    disabledx // TODO: Ojo con esto, antes el boton estaba deshabilitado
                    validate={required()}
                    defaultValue={id}
                    formClassName={classes.width50}
                  >
                    <SelectInput optionText="person.name" />
                  </ReferenceInput>
                </Grid>
              </Grid>
            </SubmitExtractor>
          </SimpleForm>
        </Card>
      </div>
    </Fragment>
  );
};

//TODO:mapStateToProps required to connect the PartnerCreate component.
const mapStateToProps = () => ({});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCreate);
