import React, { useState } from "react";

const Suggestions = () => {
  const [msg, setMsg] = useState("");
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("Déjanos tú opinión");
  const [send, setSend] = useState(false);

  const handleSubmit = async () => {
    const token = sessionStorage.getItem("date");

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/email/suggestion",
      {
        method: "post",
        body: JSON.stringify({
          subject: "Sugerencia",
          html: `<h1>Ronald De Leon</h1>
          <p>
            Mensaje: ${msg}
          </p>`,
        }),
        headers: {
          Authorization: `Bearer ${token?.substring(1, token.length - 10)}`,
          "Content-Type": "application/json",
        },
      }
    ).then((data) => data.json());

    if (res.sent) {
      setShow(false);
      setSend(true);
      setMsg("");
      setTitle("Mensaje enviado!");
      alert("Mensaje enviado!");
    } else {
      alert("Ha ocurrido un error al inviar la sugerencia, intente más tarde.");
    }
  };
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 11,
        right: 20,
        bottom: 0,
        background: "#aa0515",
        padding: 10,
        minWidth: 200,
        borderRadius: "10px 10px 0px 0px",
      }}
    >
      <p
        onClick={() => setShow(!show)}
        style={{
          marginTop: 0,
          marginBottom: 1,
          color: "white",
          textAlign: "center",
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        SUGERENCIAS
      </p>
      <div
        style={{
          height: "250px",
          background: "white",
          borderRadius: "10px",
          display: show ? "block" : "none",
          textAlign: "center",
        }}
      >
        <p style={{ color: "black", textAlign: "center", paddingTop: "10px" }}>
          {title}
        </p>
        {!send && (
          <>
            <textarea
              onInput={(e) => setMsg(e.target.value)}
              placeholder="Escribe aquí"
              style={{
                display: "block",
                width: "90%",
                margin: "5px",
                background: "transparent",
                color: "black",
                borderRadius: "5px",
                padding: "3px",
              }}
              rows={10}
            ></textarea>
            <button
              onClick={() => handleSubmit()}
              style={{
                width: "87%",
                margin: "5px",
                marginTop: "5px",
                background: "#aa0515",
                color: "white",
                borderRadius: "50px",
                padding: "7px",
              }}
            >
              Enviar
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Suggestions;
