import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import {
  Container,
  Divider,
  Grid,
  List,
  Paper,
  Typography,
} from "@material-ui/core";

import ListItemLink from "../../components/ListItemLink";
import { userTypes, roles, transactionsTypes, personTypes } from "../../Views";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    paddingTop: theme.spacing(3),
  },
  list: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexWrap: "wrap",
      "& > li": {
        flexBasis: "33.33%",
      },
    },
  },
}));

const SettingsAdmin = () => {
  const classes = useStyles();
  const translate = useTranslate();

  const iconColor = "#000000";

  return (
    <Container className={classes.container} maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="overline">
            Configuración
          </Typography>

          <Typography variant="h1">Mantenimientos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography gutterBottom variant="h4" component="h2">
              Persona
            </Typography>
            <Divider />
            <List
              className={classes.list}
              aria-labelledby="conguration subtitle"
            >
              <ListItemLink
                to="/user-types"
                primary={translate("resources.user-types.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<userTypes.icon />}
              />
              <ListItemLink
                to="/roles"
                primary={translate("resources.roles.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<roles.icon />}
              />
              <ListItemLink
                to="/transaction-type"
                primary={translate("resources.transaction-type.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<transactionsTypes.icon />}
              />
              <ListItemLink
                to="/person-type"
                primary={translate("resources.person-type.name", {
                  smart_count: 2,
                })}
                color={iconColor}
                icon={<personTypes.icon />}
              />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SettingsAdmin;
