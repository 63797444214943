export default (state, action) => {
  switch (action.type) {
    case "FILTER":
      return {
        ...state,
        id: "",
        payload: action.payload,
      };
    default:
      throw state;
  }
};
