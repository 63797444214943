import React from "react";
import {
  DateInput,
  SelectInput,
  required,
  TextInput,
  maxLength,
  number,
  ReferenceInput,
} from "react-admin";
import { minLength } from "ra-core";

import FormTitle from "../../components/FormTitle";
import { onlyText } from "../../utils/validators";
import { FormGeneral } from "../../styles/FormGeneral";

export const PersonalInfo = ({
  academicLevels,
  className,
  countries,
  documentTypes,
  economicActivities,
  formClassName,
  id,
  maritalStatuses,
  professions,
  source,
  ...props
}) => {
  const classes = FormGeneral();

  return (
    <div className={classes.root}>
      <div className={classes.width100}>
        <FormTitle title="Información personal" />
      </div>
      <ReferenceInput
        className={classes.width33}
        reference="person-type"
        source="personTypeId"
        validate={[required()]}
        {...props}
      >
        <SelectInput optionText="description" />
      </ReferenceInput>
      <TextInput
        source={source ? `${source}.name` : "name"}
        validate={[required(), onlyText]}
        className={classes.width33}
        {...props}
      />
      <TextInput
        source={source ? `${source}.lastname` : "lastname"}
        validate={[required()]}
        className={classes.width33}
        {...props}
      />
      <SelectInput
        source={source ? `${source}.sex` : "sex"}
        choices={[
          { id: "M", name: "Masculino" },
          { id: "F", name: "Femenino" },
        ]}
        validate={[required()]}
        className={classes.width33}
        {...props}
      />
      <DateInput
        source={source ? `${source}.birthday` : "birthday"}
        className={classes.width33}
        {...props}
      />
      <TextInput
        source={source ? `${source}.contactEmail` : "contactEmail"}
        validate={[required()]}
        className={classes.width33}
        {...props}
      />
      <TextInput
        source={source ? `${source}.facebook` : "facebook"}
        className={classes.width33}
        {...props}
      />
      <TextInput
        source={source ? `${source}.instagram` : "instagram"}
        className={classes.width33}
        {...props}
      />
      <TextInput
        className={classes.width33}
        validate={[number(), minLength(10), maxLength(11)]}
        source={source ? `${source}.phone` : "phone"}
        {...props}
      />
      <TextInput
        className={classes.width33}
        validate={[number(), minLength(10), maxLength(11)]}
        source={source ? `${source}.telephone` : "telephone"}
        {...props}
      />
      <TextInput
        className={classes.width33}
        source={source ? `${source}.rnc` : "rnc"}
        validate={[number(), minLength(10), maxLength(11)]}
        {...props}
      />
    </div>
  );
};
