import React, { Fragment, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { useDataProvider } from "react-admin";
import {
  Dialog,
  CardContent,
  IconButton,
  Typography,
  Card,
  Grid,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

import { makeStyles } from "@material-ui/styles";
import { format } from "../utils/enums";
import { PaymentButton } from "../Views/Profits/PaymentButton";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "3px",
    color: theme.palette.grey[500],
  },

  color: {
    color: "#2216dc",
  },
}));

const SimpleModal = ({ record = [], show, dispatch }) => {
  const [person, setPerson] = useState(0);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const handleClose = () => {
    dispatch({ type: "HIDE_STATE_MODAL" });
  };

  const personTypeIdUndefined =
    record?.partnerId?.length > 0 ? "" : record.partnerId;
  const notUndefined =
    personTypeIdUndefined !== undefined ? personTypeIdUndefined : "";
  dataProvider.getOne("partners", { id: notUndefined }).then((response) => {
    setPerson(response.data.user?.person.personTypeId);
  });

  const totalCommission =
    Number(record.totalCommissionsSonRedeemable) +
    Number(record.totalCommissionsGrandsonRedeemable);

  const retentionIsr = Math.floor(totalCommission * 10) / 100;

  const percentage = totalCommission * 0.18;
  const oneHundred = percentage;
  const thirty = percentage * 0.3;
  const totalPayment = totalCommission + percentage - thirty;

  const secondTotalPayment =
    totalCommission - oneHundred - retentionIsr + percentage;

  const changePercentage = () => {
    switch (person) {
      case 2:
        return {
          name: "100%",
          percentage: oneHundred,
          total: secondTotalPayment,
        };
      case 3:
        return {
          name: "30%",
          percentage: thirty,
          total: totalPayment,
        };
      case 1:
        return {
          name: "100%",
          percentage: percentage,
          total: totalCommission,
        };
      default:
        return 1;
    }
  };

  const test = changePercentage();

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h4">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  return (
    <Fragment>
      <Dialog
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={show}
      >
        <>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Detalle de Pago
          </DialogTitle>
          <Card className={classes.root}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={4} xs={6}>
                  <Typography variant="h6" gutterBottom>
                    Total Comisión del mes
                  </Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <Typography variant="h6" gutterBottom align="right">
                    DOP
                  </Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <Typography variant="h6" gutterBottom align="right">
                    {format(totalCommission)}
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.spacer}>&nbsp;</div>
              {totalCommission !== 0 && (
                <Grid container spacing={2}>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom>
                      TOTAL ITBIS FACTURADO
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom align="right">
                      DOP
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom align="right">
                      {format(percentage)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {totalCommission !== 0 && (
                <Grid container spacing={2}>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom>
                      {test.name} ITBIS RETENIDO
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom align="right">
                      DOP
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom align="right">
                      {format(test.percentage)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {person === 2 && (
                <Grid container spacing={2}>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom>
                      Retención de ISR 10%
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom align="right">
                      DOP
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography variant="h6" gutterBottom align="right">
                      {format(retentionIsr)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <div className={classes.spacer}>&nbsp;</div>
              {totalCommission !== 0 && (
                <Grid container spacing={2}>
                  <Grid item md={4} xs={6}>
                    <Typography
                      className={classes.color}
                      variant="h6"
                      gutterBottom
                    >
                      TOTAL A PAGAR
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography
                      className={classes.color}
                      variant="h6"
                      gutterBottom
                      align="right"
                    >
                      DOP
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Typography
                      className={classes.color}
                      variant="h6"
                      gutterBottom
                      align="right"
                    >
                      {format(test.total)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item md={4} xs={6}>
                  <Typography
                    className={classes.color}
                    variant="h6"
                    gutterBottom
                  >
                    TOTAL COMISIÓN CANJEABLE
                  </Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <Typography
                    className={classes.color}
                    variant="h6"
                    gutterBottom
                    align="right"
                  >
                    DOP
                  </Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <Typography
                    className={classes.color}
                    variant="h6"
                    gutterBottom
                    align="right"
                  >
                    {format(record.totalCommissionsSonNotRedeemable)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <PaymentButton
              record={record}
              total={record.totalCommissionsSonNotRedeemable}
              reduction={
                totalCommission - record.totalCommissionsSonNotRedeemable
              }
              totalCommission={totalCommission}
            />
          </Card>
        </>
      </Dialog>
    </Fragment>
  );
};

export default SimpleModal;
