import React from "react";
import {
  Create,
  required,
  TextInput,
  SimpleForm,
  TopToolbar,
} from "react-admin";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const TransactionTypeCreate = (props) => {
  return (
    <Create {...props} actions={<Actions {...props} />}>
      <SimpleForm redirect={props.basePath} variant="outlined">
        <TextInput fullWidth source="name" validate={required()} />
        <TextInput fullWidth source="description" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default TransactionTypeCreate;
