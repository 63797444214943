import React from "react";
import { Route } from "react-router-dom";

import { settingsAdmin } from "./Views/SettingsAdmin";
import ChangePassword from "./Views/ChangePassword";
import Login from "./Views/Login";
import Settings from "./Views/Settings";

export default [
  <Route
    exact
    path="/change-password/:token"
    component={ChangePassword}
    noLayout
  />,
  <Route exact path="/configuration/change-password" component={Settings} />,
  <Route exact path="/login" component={Login.loginPage} noLayout />,
  <Route exact path="/settings/maintenances" component={settingsAdmin} />,
  <Route
    exact
    path="/forgot-password"
    component={Login.ForgotPasswordPage}
    noLayout
  />,
];
