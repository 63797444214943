const token = sessionStorage.getItem("date");
const reloaded = sessionStorage.getItem("reloaded");

export const fetchWithToken = (endPoint, method = 'get') => {

    if(!token && !reloaded) {
        sessionStorage.setItem("reloaded",'1');
        return setTimeout(() => {
            window.location.reload()
        }, 500);
    };

    sessionStorage.removeItem("reloaded");
    return fetch(process.env.REACT_APP_API_URL + endPoint,{method, headers:{
        "Authorization": `Bearer ${token?.substring(1,token.length - 10)}`
    }})
}