import { regex } from "react-admin";
import moment from "moment";
import "moment-timezone";

export const onlyText = regex(
  /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
  "Este campo solo acepta texto"
);

export const minLength = (max) => (value) =>
  value && value.length < max
    ? `Debe ser al menos ${max} caracteres `
    : undefined;

export const validateEqualPasswords = ({ password, confirmPassword }) => {
  const errors = {};

  if (password && password !== confirmPassword) {
    errors.confirmPassword = "Las contraseñas deben coincidir";
  }
  return errors;
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const momentObj = (value) => {
  const date = moment(value)
    .tz("America/New_York")
    .local()
    .format("DD/MM/YYYY");

  return date;
};

export const changeText = (record) => {
  switch (record.name) {
    case "father":
      return "Propietario";
    case "son":
      return "Hijo";
    case "grandson":
      return "Nieto";
    default:
      break;
  }
  return record.name;
};
