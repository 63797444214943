import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PartnerList from "./PartnerList";
import PartnerCreate from "./PartnerCreate";
import PartnerShow from "./PartnerShow";

export default {
  list: PartnerList,
  create: PartnerCreate,
  icon: RecentActorsIcon,
  show: PartnerShow,
};
