import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";

import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  statistics: {
    marginTop: theme.spacing(3),
  },
  notifications: {
    marginTop: theme.spacing(6),
  },
  projects: {
    marginTop: theme.spacing(6),
  },
  todos: {
    marginTop: theme.spacing(6),
  },
  card: {
    backgroundColor: "blue",
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="lx">
      <Header />
    </Container>
  );
};

export default Dashboard;
