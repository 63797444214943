import React from "react";
import { Edit, FormTab, ListButton, TabbedForm, TopToolbar } from "react-admin";

import { FormGeneral } from "../../styles/FormGeneral";
import { PersonalInfo } from "./PersonalInfo";

const CreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const PersonEdit = (props) => {
  const classes = FormGeneral();

  return (
    <Edit title=" " {...props} actions={<CreateActions {...props} />}>
      <TabbedForm redirect={props.basePath} variant="outlined">
        <FormTab label="General" contentClassName={classes.root}>
          <PersonalInfo formClassName={classes.width100} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default PersonEdit;
