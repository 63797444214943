import { Box, Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { Fragment, useEffect, useReducer } from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  number,
  TextField,
  TextInput,
  useListContext,
} from "react-admin";
import { decode } from "js-base64";
import { Form } from "react-final-form";
import { filterReducer } from "../../reducer";
import { format } from "../../utils/enums";

const PostFilterForm = (props) => {
  const { filterValues, setFilters, hideFilter } = useListContext();

  useEffect(() => {
    props.dispatch({ type: "FILTER", payload: { id: props.filterValues.id } });
  }, []);

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      props.dispatch({ type: "FILTER", payload: values });
      setFilters(values);
    } else {
      hideFilter("form");
    }
  };
  const resetFilter = () => {
    setFilters({}, []);
  };

  if (props.context === "button") {
    return null;
  }
  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                <TextInput
                  source="id"
                  variant="outlined"
                  validate={[number()]}
                  resettable
                  alwaysOn
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mb={3}>
                <Button variant="outlined" color="primary" type="submit">
                  Filtrar
                </Button>
              </Box>
              <Box component="span" ml={1.5} mb={3}>
                <Button variant="outlined" onClick={resetFilter}>
                  Limpiar
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

const TransactionList = ({ permissions, ...props }) => {
  const role = decode(sessionStorage.getItem("right")|| "");

  const [filterId, dispatch] = useReducer(filterReducer, {
    payload: { id: null },
  });

  const userId = filterId?.payload?.id || decode(sessionStorage.getItem("s")|| "");

  const displayFilters =
    role === "customer" ? <Fragment /> : <PostFilterForm dispatch={dispatch} />;

  const minWidth = 500;

  return (
    <>
      <List
        filters={displayFilters}
        filterDefaultValues={{ id: userId }}
        sortable={false}
        perPage={25}
        exporter={false}
        {...props}
      >
        <Datagrid>

          {window.screen.width > minWidth &&<TextField emptyText="-" source="id" />}
          <DateField label="Fecha" source="createdOn" />
          {window.screen.width > minWidth &&<TextField
            label="Tipo de transacción"
            source="transactionType.description"
          />}
          {window.screen.width > minWidth &&<FunctionField
            label="Numero de orden"
            render={(record) => (
              <a
                href={`https://statoord.com/es/index.php?controller=pdf-invoice&id_order=${record.orderIdFromPrestashop}`}
              >
                {record.order.reference}
              </a>
            )}
          />}
          {window.screen.width > minWidth &&<FunctionField
            label="Numero de nota de crédito"
            render={(record) => (
              <a
                href={`https://statoord.com/es/index.php?controller=pdf-order-slip&id_order_slip=${record.orderSlip.id}`}
              >
                {record.orderSlip.id}
              </a>
            )}
          />}
          <FunctionField
            label="Crédito"
            emptyText="-"
            render={(record) => format(record.credit)}
          />
          <FunctionField
            label="Débito"
            emptyText="-"
            render={(record) => format(record.debit)}
          />
          <FunctionField
            label="Balance"
            emptyText="-"
            render={(record) => format(record.balance)}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default TransactionList;
