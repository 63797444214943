import React, { Fragment, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { decode } from "js-base64";
import { Route, useHistory } from "react-router-dom";
import { Drawer, makeStyles } from "@material-ui/core";

import TransactionTypeCreate from "./TransactionTypeCreate";
import TransactionTypeEdit from "./TransactionTypeEdit";

const useStyles = makeStyles(() => ({
  drawerContent: { width: 600, maxWidth: "100vw" },
}));

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput source="name" alwaysOn resettable />
  </Filter>
);

const Actions = ({ basePath, role }) => (
  <TopToolbar>
    {role === "admin" && <CreateButton basePath={basePath} />}
  </TopToolbar>
);

const TransactionTypeEditList = ({ permissions, ...props }) => {
  const role = decode(sessionStorage.getItem("right")|| "");
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/transaction-type");
  }, [history]);

  return (
    <Fragment>
      <List
        filters={<Filters />}
        sort={{ field: "id", order: "DESC" }}
        actions={<Actions permissions={permissions} role={role} />}
        perPage={25}
        {...props}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          {role === "admin" && <EditButton />}
        </Datagrid>
      </List>
      <Route path="/transaction-type/create">
        {({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <TransactionTypeCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
            />
          </Drawer>
        )}
      </Route>
      <Route path="/transaction-type/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <TransactionTypeEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default TransactionTypeEditList;
