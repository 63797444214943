import FaceIcon from "@material-ui/icons/Face";
import UserList from "./UserList";
import UserShow from "./UserShow";
import UserCreate from "./UserCreate";

export default {
  list: UserList,
  icon: FaceIcon,
  create: UserCreate,
  show: UserShow,
};
