import { makeStyles } from "@material-ui/core";

export const FormShow = makeStyles((theme) => {
  const fontSizeShowField = "15px";

  return {
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    divider: {
      backgroundColor: "#979797",
      height: "33px",
      margin: "1px 25px 0px 30px",
      width: "2px",
    },
    buttonPosition: {
      marginInlineEnd: "auto",
    },
    buttonSpacing: {
      margin: "0rem 1rem 1rem 0rem;",
    },
    borderBottom: {
      "& > div:last-child": {
        paddingBottom: theme.spacing(3),
      },
    },
    listItem: {
      padding: "0",
    },
    listButton: {
      padding: "10px",
      width: "100%",
      "& > span": { justifyContent: "end" },
    },
    [theme.breakpoints.up("md")]: {
      flexParent: {
        display: "flex",
        flexWrap: "wrap",
      },
      width100: {
        width: "100%",
        fontSize: fontSizeShowField,
      },
      width50: {
        width: "50%",
        fontSize: fontSizeShowField,
      },
      width33: {
        width: "33%",
        "& .MuiTypography-body2": {
          fontSize: fontSizeShowField,
        },
      },
      width25: {
        width: "25%",
        fontSize: fontSizeShowField,
      },
    },
    form: {
      "& section": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      },
      "& > label": {
        display: "none",
      },
    },
  };
});
