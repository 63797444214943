import polyglotI18nProvider from "ra-i18n-polyglot";
import React from "react";
import { Admin, Resource } from "react-admin";
import "typeface-roboto";
import customRoutes from "./customRoutes";
import spanishMessages from "./i18n/es";
import MyLayout from "./layout/MyLayout";
import { authProvider, dataProvider } from "./Providers";
import { theme } from "./theme";
import {
  authentications,
  dashboard,
  employees,
  login,
  partners,
  persons,
  personTypes,
  profits,
  roles,
  suppliers,
  transactions,
  transactionsTypes,
  users,
  userTypes,
} from "./Views";

const messages = {
  es: { ...spanishMessages },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "es", {
  allowMissing: true,
});

const App = () => (
  <Admin
    authProvider={authProvider}
    customRoutes={customRoutes}
    loginPage={login.loginPage}
    dashboard={dashboard}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    theme={theme}
    layout={MyLayout}
  >
    <Resource name="persons" {...persons} />
    <Resource name="auth/signup" {...authentications} />
    <Resource name="users" {...users} />
    <Resource name="employees" {...employees} />
    <Resource name="user-types" {...userTypes} />
    <Resource name="suppliers" {...suppliers} />
    <Resource name="partners" {...partners} />
    <Resource name="partners/relations" />
    <Resource name="profits" {...profits} />
    <Resource name="partner-types" />
    <Resource name="person-type" {...personTypes} />
    <Resource name="transaction-type" {...transactionsTypes} />
    <Resource name="transactions" {...transactions} />
    <Resource name="invoices" />
    <Resource name="users/statistics" />
    <Resource name="roles" {...roles} />
  </Admin>
);

export default App;
