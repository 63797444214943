export const format = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(value));
};

export const sume = (value1, value2) => {
  return value1 + value2;
};
