import React, { Fragment, useCallback } from "react";
import {
  CreateButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  DateField,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  EmailField,
} from "react-admin";
import { decode } from "js-base64";
import { Route, useHistory } from "react-router-dom";
import { Drawer, makeStyles } from "@material-ui/core";

import UserEdit from "./UserEdit";
import FullNameField from "../../components/FullNameField";

const useStyles = makeStyles(() => ({ drawerContent: { width: 450 } }));

const Filters = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <TextInput source="id" alwaysOn resettable />
      <TextInput source="person.contactEmail" alwaysOn resettable />
    </Filter>
  );
};

const Actions = ({ basePath, role }) => (
  <TopToolbar>
    {role === "admin" && <CreateButton basePath={basePath} style={{background:'black', color:'white'}}/>}
  </TopToolbar>
);
const UserList = ({ ...props }) => {
  const role = decode(sessionStorage.getItem("right")|| "");
  const classes = useStyles();
  const history = useHistory();
  console.log(role);
  const handleClose = useCallback(() => {
    history.push("/users");
  }, [history]);

  return (
    <Fragment>
      <List
        actions={<Actions role={role} />}
        filters={<Filters />}
        perPage={25}
        sort={{ field: "id", order: "DESC" }}
        {...props}
      >
        <Datagrid size="small">
          <TextField emptyText="-" source="id" />
          <FullNameField user={true} />
          <EmailField source="person.contactEmail" />
          <ReferenceField
            link={false}
            reference="user-types"
            source="userTypeId"
          >
            <TextField size="small" source="description" />
          </ReferenceField>
          <ReferenceField link={false} reference="roles" source="roleId">
            <TextField size="small" source="description" />
          </ReferenceField>
          <DateField source="person.createdOn" />
          {role === "admin" && <EditButton />}
          <ShowButton />
        </Datagrid>
      </List>
      <Route path="/users/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <UserEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default UserList;
