import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import AvatarField from "./AvatarField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const FullNameField = ({ record = [], size, user }) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      <AvatarField className={classes.avatar} record={record} size={size} />
      {user && record.person ? (
        <>
          {record.person.name} {record.person.lastname}
        </>
      ) : (
        <>
          {record.name} {record.lastname}
        </>
      )}
    </div>
  ) : null;
};

FullNameField.propTypes = {
  size: PropTypes.string,
};

FullNameField.defaultProps = {
  source: "lastName",
  label: "Nombre completo",
};

export default FullNameField;
