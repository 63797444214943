import { makeStyles } from "@material-ui/core";

export const FormGeneral = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  borderBottom: {
    "& > div:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  [theme.breakpoints.up("md")]: {
    flexParent: {
      "& > div:last-child > span": {
        display: "flex",
        flexWrap: "wrap",
      },
    },
    width100: {
      width: "100%",
    },
    width75: {
      width: "74%",
    },
    width50: {
      width: "49%",
    },
    width33: {
      width: "32%",
    },
    width25: {
      width: "24%",
    },
  },
  form: {
    "& section": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    "& > label": {
      display: "none",
    },
  },
  stylesForm: {
    flexBasis: 700,
    paddingBottom: 125,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));
