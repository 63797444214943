import React from "react";
import {
  Create,
  FormTab,
  ListButton,
  TabbedForm,
  TopToolbar,
} from "react-admin";

import { FormGeneral } from "../../styles/FormGeneral";
import { PersonalInfo } from "./PersonalInfo";

const Actions = (props) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} />
  </TopToolbar>
);

const PersonCreate = (props) => {
  const classes = FormGeneral();

  return (
    <Create actions={<Actions />} title=" " {...props}>
      <TabbedForm redirect={props.basePath} variant="outlined">
        <FormTab label="General" contentClassName={classes.root}>
          <PersonalInfo formClassName={classes.width100} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default PersonCreate;
