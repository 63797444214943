import React from "react";
import { makeStyles } from "@material-ui/core";

import RenderLineChart from "./RenderLineChart";

const Styles = makeStyles(() => ({
  flex: {
    display: "flex",
    marginBottom: 50,
  },
}));

export default ({ statisticsData, userData, sonAndGrandsons }) => {
  const classes = Styles();

  return (
    <>
      <div className={classes.flex}>
        <RenderLineChart
          userData={userData}
          statisticsData={statisticsData}
          sonAndGrandsons={sonAndGrandsons}
        />
      </div>
    </>
  );
};
