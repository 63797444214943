import React from "react";
import {
  Edit,
  FormTab,
  ListButton,
  required,
  TabbedForm,
  TextInput,
  TopToolbar,
  PasswordInput,
} from "react-admin";

import FormTitle from "../../components/FormTitle";
import { FormGeneral } from "../../styles/FormGeneral";

const redirect = (basePath, id, data) => `users/${data.userId}`;

const Actions = ({ data }) => (
  <TopToolbar>
    <ListButton to={data ? `/users${data.userId}/show/auth` : ``} />
  </TopToolbar>
);

const AuthenticationEdit = (props) => {
  const classes = FormGeneral();

  return (
    <Edit title=" " {...props} actions={<Actions />}>
      <TabbedForm redirect={redirect} variant="outlined">
        <FormTab label="General" contentClassName={classes.root}>
          <FormTitle
            title="Información de Autenticación"
            formClassName={classes.width100}
          />
          <TextInput
            formClassName={classes.width100}
            fullWidth
            source="email"
            validate={required()}
          />
          <PasswordInput
            formClassName={classes.width50}
            fullWidth
            source="password"
            validate={required()}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default AuthenticationEdit;
