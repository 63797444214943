import React, { Fragment, useEffect, useReducer, useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  FunctionField,
  DateField,
  ShowButton,
  useListContext,
  useTranslate,
  number,
} from "react-admin";
import { Button, Box, InputAdornment } from "@material-ui/core";
import { Form } from "react-final-form";
import SearchIcon from "@material-ui/icons/Search";
import { decode } from "js-base64";
import * as moment from "moment";
import "moment/locale/es"; // without this line it didn't work

import Statistics from "../../components/Statistics";
import { format } from "../../utils/enums";
import { filterReducer } from "../../reducer";
import { fetchWithToken } from "../../utils/fetch";

const AdminFilter = (props) => {
  const { filterValues, setFilters, hideFilter } = useListContext();
  const translate = useTranslate();

  useEffect(() => {
    props.dispatch({ type: "FILTER", payload: { id: props.filterValues.id } });
  }, []);

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      props.dispatch({ type: "FILTER", payload: values });
      setFilters(values);
    } else {
      hideFilter("form");
    }
  };
  const resetFilter = () => {
    setFilters({}, []);
  };

  if (props.context === "button") {
    return null;
  }
  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                <TextInput
                  label={translate("resources.profits.fields.id")}
                  source="id"
                  variant="outlined"
                  validate={[number()]}
                  resettable
                  defaultValue={props.userId}
                  alwaysOn
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mb={3}>
                <Button variant="outlined" color="primary" type="submit">
                  Filtrar
                </Button>
              </Box>
              <Box component="span" ml={1.5} mb={3}>
                <Button variant="outlined" onClick={resetFilter}>
                  Limpiar
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

const ProfitList = ({ permissions, ...props }) => {
  const [userData, setUserData] = useState();
  const [sonAndGrandsons, setSonAndGrandsons] = useState();
  const [statisticsData,setStatisticsData] = useState();

  const [filterId, dispatch] = useReducer(filterReducer, {
    payload: { id: null },
  });
  const role = decode(sessionStorage.getItem("right")|| "");
  const userId = filterId?.payload?.id || decode(sessionStorage.getItem("s")|| "");

  useEffect(() => {
    fetchWithToken(
      `/users/${filterId.payload?.id || userId}`
    )
      .then((res) => res.json())
      .then((data) => setUserData(data));
      fetchWithToken(
      `/profits/sons-and-grandsons?userId=${
        filterId.payload?.id || userId
      }`
    )
      .then((response) => response.json())
      .then((data) => setSonAndGrandsons(data));
      fetchWithToken(
        `/profits/statistics?userId=${filterId.payload?.id || userId}`
      )
        .then((response) => response.json())
        .then((data) => setStatisticsData(data));
  }, [filterId.payload.id, userId]);

  const displayFilters =
    role === "customer" ? (
      <Fragment />
    ) : (
      <AdminFilter userId={userId} dispatch={dispatch} />
    );

    const minWidth = 500;

  return (
    <>
      <Statistics
        userData={userData}
        statisticsData={statisticsData}
        sonAndGrandsons={sonAndGrandsons}
      />
      <List
        filterDefaultValues={{ id: userId }}
        filters={displayFilters}
        sort={{ field: "id", order: "DESC" }}
        exporter={false}
        perPage={25}
        {...props}
      >
        <Datagrid >
        {window.screen.width > minWidth &&<TextField emptyText="-" source="id" label={'id'} />}
        {window.screen.width > minWidth &&<FunctionField
            label="Nombre Completo"
            render={(record) =>
              `${record?.partner?.user?.person?.name} ${record?.partner?.user?.person?.lastname}`
            }
          />}
          <FunctionField label="Fecha De Corte" source="createdOn" render={(record) => {
              return moment(record.createdOn).format("DD-MM-YYYY").toUpperCase();
            }} />
          {window.screen.width > minWidth &&<FunctionField
            label="Mes"
            source="paidOut"
            render={(record) => {
              return moment(record.createdOn).format("MMMM").toUpperCase();
            }}
          />}
          <FunctionField
            label="Estado del pago"
            source="paidOut"
            render={(record) =>
              record.paidOut ? "Pago Realizado" : "Pago No Realizado"
            }
          />
          <FunctionField
            textAlign="left"
            label="Balance"
            emptyText="-"
            source="totalPurchasedWithTax"
            render={(record) =>
              format(
                Math.abs(
                  record.totalCommissionsGrandsonRedeemable +
                    record.totalCommissionsSonRedeemable
                )
              )
            }
          />
          <ShowButton />
        </Datagrid>
      </List>
    </>
  );
};

export default ProfitList;
