import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { Button } from "react-admin";
import ExportExcel from "react-export-excel";
import useSelectDataProvider from "../hooks/useSelectDataProvider";
import {
  totalCash,
  totalPurchasedWithoutTax,
} from "../Views/Profits/ProfitShow";

export default ({ id }) => {
  const userData = id ? useSelectDataProvider(`invoices/${id}`) : "";

  const ExcelFile = ExportExcel.ExcelFile;
  const ExcelSheet = ExportExcel.ExcelSheet;
  const ExcelColumn = ExportExcel.ExcelColumn;

  userData &&
    userData.forEach((name) => {
      const isTotalPurchasedWithTax = totalPurchasedWithoutTax(name);
      const isTotalCash = totalCash(name);

      // eslint-disable-next-line no-lone-blocks
      {
        name.fullName = name?.profit?.partner?.user?.person?.name;
        name.lastName = name?.profit?.partner?.user?.person?.lastname;
        name.totalPurchasedWithTax = isTotalPurchasedWithTax;
        name.totalCash = isTotalCash;
      }
    });

  return (
    <div>
      <ExcelFile
        element={
          <Button
            label="Exportar a Excel"
            color="secondary"
            startIcon={<GetAppIcon />}
            variant="outlined"
          />
        }
        filename="facturas_socios"
      >
        <ExcelSheet
          data={userData}
          name="Detalle de facturas
        "
        >
          <ExcelColumn label="Nombres" value="fullName" />
          <ExcelColumn label="Apellidos" value="lastName" />
          <ExcelColumn label="Fecha de compra" value="purchaseDate" />
          <ExcelColumn
            label="Total de compras  sin impuestos"
            value="totalPurchasedWithTax"
          />
          <ExcelColumn
            label="Total de compras  con impuestos"
            value="totalPurchased"
          />
          <ExcelColumn
            label="Comisiones totales 3% hijo"
            value="totalCommissionsSonRedeemable"
          />
          <ExcelColumn
            label="Comisiones totales 1% Nieto"
            value="totalCommissionsGrandsonRedeemable"
          />
          <ExcelColumn
            label="Comisiones totales 1% Canjeable"
            value="totalCommissionsSonNotRedeemable"
          />
          <ExcelColumn
            label="Total de comisión en efectivo"
            value="totalCash"
          />
          <ExcelColumn label="ID" value="psInvoiceLink" />
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};
