import React, { useReducer } from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  TopToolbar,
  Datagrid,
  DateField,
  Button,
  Link,
  FunctionField,
  ReferenceManyField,
} from "react-admin";
import MoneyIcon from "@material-ui/icons/Money";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { FormShow } from "../../styles/FormShow";
import { stateModalReducer } from "../../reducer";
import FormTitle from "../../components/FormTitle";
import SimpleModal from "../../components/SimpleModal";
import { format } from "../../utils/enums";

const ModalButton = ({ record, dispatch }) => {
  const classes = FormShow();

  return (
    <Button
      color="primary"
      className={classes.buttonSpacing}
      label="Ver detalle de pago"
      onClick={() => dispatch({ type: "SHOW_STATE_MODAL", payload: record })}
      startIcon={<MoneyIcon />}
      variant="contained"
    />
  );
};

const Actions = ({ basePath, data }) => {
  const classes = FormShow();
  const [states, dispatch] = useReducer(stateModalReducer, {
    showModal: false,
  });

  return (
    <TopToolbar>
      <Button
        className={classes.buttonPosition}
        component={Link}
        label="Atrás"
        startIcon={<ArrowBackIosIcon />}
        to={basePath}
      />
      <ModalButton dispatch={dispatch} />
      <SimpleModal show={states.showModal} dispatch={dispatch} record={data} />
    </TopToolbar>
  );
};

const totalPurchasedWithTax = (record) => {
  const isNumberTax = Number(record.tax);
  const tax = isNumberTax * 100;
  return Number(record.totalPurchased) + tax;
};

function totalCash(record) {
  const totalSon = Number(record.totalCommissionsSonRedeemable);
  const totalGrandSon = Number(record.totalCommissionsGrandsonRedeemable);
  return totalGrandSon + totalSon;
}

const ProfitShow = ({ ...props }) => {
  const classes = FormShow();

  return (
    <Show actions={<Actions {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Facturas" contentClassName={classes.flexParent}>
          <FormTitle title="Facturas" className={classes.width100} />
          <ReferenceManyField
            addLabel={false}
            reference="invoices"
            target="id"
            className={classes.width100}
          >
            <Datagrid>
              <FunctionField
                label="Cliente"
                render={(record) =>
                  `${record?.profit?.partner?.user?.person?.name} ${record?.profit?.partner?.user?.person?.lastname}`
                }
              />
              <DateField
                label="Fecha de compra"
                emptyText="-"
                source="purchaseDate"
              />
              <FunctionField
                textAlign="left"
                label="Total de compras con impuestos"
                emptyText="-"
                source="totalPurchasedWithTax"
                render={(record) => format(totalPurchasedWithTax(record))}
              />
              <FunctionField
                textAlign="left"
                label="Total de compras sin impuestos"
                emptyText="-"
                render={(record) => format(record.totalPurchased)}
              />
              <FunctionField
                textAlign="left"
                label="Comisiones totales 3%"
                emptyText="-"
                render={(record) =>
                  format(record.totalCommissionsSonRedeemable)
                }
              />
              <FunctionField
                textAlign="left"
                label="Comisiones totales 1% Nieto"
                emptyText="-"
                render={(record) =>
                  format(record.totalCommissionsGrandsonRedeemable)
                }
              />
              <FunctionField
                textAlign="left"
                label="Comisiones totales 1% Canjeable"
                emptyText="-"
                render={(record) =>
                  format(record.totalCommissionsSonNotRedeemable)
                }
              />
              <FunctionField
                textAlign="left"
                label="Total de comisión en efectivo"
                emptyText="-"
                render={(record) => format(totalCash(record))}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default ProfitShow;
