import React from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TopToolbar,
  TextInput,
  required,
  DateInput,
  email,
  ReferenceInput,
  SelectInput,
  ListButton,
} from "react-admin";

import { FormGeneral } from "../../styles/FormGeneral";

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const EmployeeEdit = (props) => {
  const classes = FormGeneral();

  return (
    <Edit title=" " {...props} actions={<Actions {...props} />}>
      <TabbedForm redirect={props.basePath} variant="outlined">
        <FormTab label="Empleado" contentClassName={classes.root}>
          <ReferenceInput
            disabled
            formClassName={classes.width50}
            fullWidth
            reference="persons"
            source="personId"
          >
            <SelectInput optionText="firstName" validate={[required()]} />
          </ReferenceInput>
          <ReferenceInput
            formClassName={classes.width50}
            fullWidth
            reference="countries"
            source="countryId"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput
            formClassName={classes.width50}
            fullWidth
            reference="branch-offices"
            source="branchOfficeId"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            formClassName={classes.width50}
            fullWidth
            reference="positions"
            source="positionId"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput
            formClassName={classes.width50}
            fullWidth
            source="corporateEmail"
            validate={[required(), email()]}
          />
          <TextInput
            formClassName={classes.width50}
            fullWidth
            source="phoneNumber"
          />
          <DateInput
            formClassName={classes.width50}
            fullWidth
            source="effectiveDate"
          />
          <DateInput
            formClassName={classes.width50}
            fullWidth
            source="expirationDate"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default EmployeeEdit;
