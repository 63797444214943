export const applicationsApproved = "Solicitudes aprobadas";
export const changeMade = "Cambio efectuado!";
export const incorrectPassword = "Contraseña incorrecta";
export const incorrectUserOrPassword = "Usuario o Contraseña incorrecta!";
export const nipConfirm = "Nip confirmado!";
export const passwordDoNotMatch = "La contraseña no coinciden";
export const thereWasProblem = "Hubo un problema";
export const unlockedUser = "Usuario desbloqueado";
export const updatedPassword = "Se ha actualizado su contraseña";
export const userBlocked = "Usuario bloqueado";
