import React, { Fragment } from "react";
import {
  DateField,
  EditButton,
  ListButton,
  ReferenceField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";

import FormTitle from "../../components/FormTitle";
import { FormShow } from "../../styles/FormShow";
const PartnerShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const PartnerShow = ({ ...props }) => {
  const classes = FormShow();

  return (
    <Fragment>
      <Show {...props} actions={<PartnerShowActions />}>
        <TabbedShowLayout className={classes.borderBottom}>
          <Tab label="General" contentClassName={classes.flexParent}>
            <FormTitle
              title="Información personal"
              className={classes.width100}
            />
            <TextField className={classes.width25} source="people[0].name" />
            <TextField
              className={classes.width25}
              source="people[0].lastname"
            />
            <DateField
              className={classes.width25}
              source="people[0].birthday"
              options={{ format: "DD-MM-YYYY" }}
            />
            <SelectField
              source="people[0].sex"
              choices={[
                { id: "m", name: "Masculino" },
                { id: "f", name: "Femenino" },
              ]}
              className={classes.width25}
            />
            <ReferenceField
              source="userTypeId"
              reference="user-types"
              link={false}
              allowEmpty
              className={classes.width25}
            >
              <TextField source="description" />
            </ReferenceField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Fragment>
  );
};

export default PartnerShow;
