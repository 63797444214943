import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";

const AvatarField = ({ record, size = "25", className }) =>
  record ? (
    <Avatar
      src={`${record.avatar}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    />
  ) : null;

AvatarField.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

export default AvatarField;
