import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EmployeeList from "./EmployeeList";
import EmployeeShow from "./EmployeeShow";
import EmployeeEdit from "./EmployeeEdit";
import EmployeeCreate from "./EmployeeCreate";

export default {
  list: EmployeeList,
  icon: AccountBoxIcon,
  edit: EmployeeEdit,
  create: EmployeeCreate,
  show: EmployeeShow,
};
