import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MoneyIcon from "@material-ui/icons/Money";
import moment from "moment";
import React, { useMemo, useReducer } from "react";
import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TopToolbar,
} from "react-admin";
import ExcelSheet from "../../components/ExcelSheet";
import FormTitle from "../../components/FormTitle";
import SimpleModal from "../../components/SimpleModal";
import { FormShow } from "../../styles/FormShow";
import { format } from "../../utils/enums";
import { stateModalReducer } from "./../../reducer";

const ModalButton = ({ record, dispatch }) => {
  const classes = FormShow();

  return (
    <Button
      color="primary"
      className={classes.buttonSpacing}
      label="Ver detalle de pago"
      onClick={() => dispatch({ type: "SHOW_STATE_MODAL", payload: record })}
      startIcon={<MoneyIcon />}
      variant="contained"
    />
  );
};

const Actions = React.memo(({ basePath, data }) => {
  const classes = FormShow();
  const [states, dispatch] = useReducer(stateModalReducer, {
    showModal: false,
  });

  return (
    <TopToolbar>
      <Button
        className={classes.buttonPosition}
        component={Link}
        label="Atrás"
        startIcon={<ArrowBackIosIcon />}
        to={basePath}
      />
      <ModalButton dispatch={dispatch} />
      <SimpleModal show={states.showModal} dispatch={dispatch} record={data} />
      <ExcelSheet id={data?.id} />
    </TopToolbar>
  );
});

export function totalPurchasedWithoutTax(record) {
  const isNumberTax = Number(record.tax);
  const tax = record.totalPurchased * isNumberTax;
  return Number(record.totalPurchased) - tax;
}

export function totalCash(record) {
  const totalSon = Number(record.totalCommissionsSonRedeemable);
  const totalGrandSon = Number(record.totalCommissionsGrandsonRedeemable);
  return totalGrandSon + totalSon;
}

const ProfitShow = (props) => {
  const classes = FormShow();
  const dataProps = useMemo(() => props, [props]);

  return (
    <Show actions={<Actions {...dataProps} />} {...dataProps}>
      <TabbedShowLayout>
        <Tab label="Facturas" contentClassName={classes.flexParent}>
          <FormTitle title="Facturas" className={classes.width100} />
          <ReferenceManyField
            addLabel={false}
            reference="invoices"
            target="id"
            className={classes.width100}
          >
            <Datagrid>
            <FunctionField
                textAlign="left"
                label="Orden"
                emptyText="-"
                render={(record) => record.prestashopId}
              />
              <FunctionField
                label="Fecha de compra"
                emptyText="-"
                source="purchaseDate"
                render={(record) =>
                  moment(record.purchaseDate).format("DD/MM/YYYY")
                }
              />
              <FunctionField
                textAlign="left"
                label="Total de compras con impuestos"
                emptyText="-"
                source="totalPurchasedWithTax"
                render={(record) => {
                  return format(record.totalPurchased);
                }}
              />
              <FunctionField
                textAlign="left"
                label="Total de compras sin impuestos"
                emptyText="-"
                render={(record) => format(record.totalPurchasedWithoutTax)}
              />
              <FunctionField
                textAlign="left"
                label="Comisiones totales 3% hijo"
                emptyText="-"
                render={(record) =>
                  format(record.totalCommissionsSonRedeemable)
                }
              />
              <FunctionField
                textAlign="left"
                label="Comisiones totales 1% Nieto"
                emptyText="-"
                render={(record) =>
                  format(record.totalCommissionsGrandsonRedeemable)
                }
              />
              <FunctionField
                textAlign="left"
                label="Comisiones totales 1% Canjeable"
                emptyText="-"
                render={(record) =>
                  format(record.totalCommissionsSonNotRedeemable)
                }
              />
              <FunctionField
                textAlign="left"
                label="Total de comisión en efectivo"
                emptyText="-"
                render={(record) => format(totalCash(record))}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default ProfitShow;
