import { colors } from "@material-ui/core";
import palette from "../palette";

export default {
  contained: {
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14)",
    backgroundColor: colors.grey[100],
    "&:hover": {
      backgroundColor: colors.grey[300],
    },
  },
  containedPrimary: {
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14)",
    background: palette.gradient.primary,
    backgroundPosition: "left",
    transition: "all 0.3s linear",
    "&:hover": {
      background: "#34D597)",
    },
  },
};
