import React, { Fragment, useCallback } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  CreateButton,
} from "react-admin";
import { Route, useHistory } from "react-router-dom";
import { Drawer, makeStyles } from "@material-ui/core";

import SupplierCreate from "./SupplierCreate";
import SupplierEdit from "./SupplierEdit";

const useStyles = makeStyles(() => ({
  drawerContent: { width: 600, maxWidth: "100vw" },
}));

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput source="name" alwaysOn resettable />
  </Filter>
);

const Actions = ({ permissions, basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const SupplierList = ({ permissions, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/suppliers");
  }, [history]);

  return (
    <Fragment>
      <List
        filters={<Filters />}
        sort={{ field: "id", order: "DESC" }}
        actions={<Actions permissions={permissions} />}
        perPage={25}
        {...props}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          <EditButton />
        </Datagrid>
      </List>
      <Route path="/suppliers/create">
        {({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <SupplierCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
            />
          </Drawer>
        )}
      </Route>
      <Route path="/suppliers/:id">
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <SupplierEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default SupplierList;
