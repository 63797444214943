import React from "react";
import { Form } from "react-final-form";

const CustomForm = ({ children, onSubmit }) => {
  return (
    <Form
      variant="outlined"
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          {children}
        </form>
      )}
    />
  );
};

export default CustomForm;
