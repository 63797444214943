import crudProvider from "@fusionworks/ra-data-nest-crud";
import { fetchUtils } from "react-admin";

const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }
  const token = sessionStorage.getItem("date");
  options.headers.set("Authorization", `Bearer ${token.substring(1,token.length - 10)}`);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = crudProvider(process.env.REACT_APP_API_URL, fetchJson);

export default dataProvider;