import spanishMessages from "@blackbox-vision/ra-language-spanish";

export default {
  ...spanishMessages,
  resources: {
    menu: {
      subMenu: {
        config: "Configuración",
      },
      secondSubMenu: {
        profit: "Contabilidad",
      },
      routes: { maintenances: "Mantenimientos" },
    },
    persons: {
      name: "Persona |||| Personas",
      fields: {
        lastname: "Apellidos",
        name: "Nombre",
        address: "Dirección",
        birthday: "Fecha de nacimiento",
        sex: "Sexo",
        contactEmail: "Correo electrónico",
        phone: "Celular",
        telephone: "Teléfono",
        rnc: "RNC O Cédula",
        personTypeId: "Tipo de persona",
      },
    },
    partners: {
      name: "Socio |||| Socios",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    suppliers: {
      name: "Suplidor |||| Suplidores",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "person-type": {
      name: "Tipo de persona |||| Tipo de personas",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    "user-types": {
      name: "Tipo de usuario ||||  Tipo de usuarios",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    users: {
      name: "Usuario |||| Usuarios",
      fields: {
        password: "Contraseña",
        stateId: "Estado",
        partnerId: "Socio",
        email: "Email",
        roleId: "Rol",
        supplierId: "Suplidor",
        username: "Nombre del usuario",
        userTypeId: "Tipo de usuario",
        confirmPassword: "Confirmar contraseña",
        parent: "Socio Padre",
        child: "Socio Hijo",
        person: {
          name: "Nombre",
          contactEmail: "Correo electrónico",
          lastname: "Apellidos",
          birthday: "Fecha de Nacimiento",
          sex: "Sexo",
          createdOn: "Fecha de creación",
        },
      },
    },
    roles: {
      name: "Rol ||||  Roles",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    profits: {
      name: "Estado de cuenta |||| Estados de cuentas",
      fields: {
        name: "País",
        nationality: "Nacionalidad",
        id: "Id del usuario",
      },
    },
    "transaction-type": {
      name: "Tipo De Transacción |||| Tipos De Transacciónes",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
    transactions: {
      name: "Estado de saldo a favor |||| Estado de saldo a favor",
      fields: {
        name: "Nombre",
        description: "Descripción",
      },
    },
  },
};
