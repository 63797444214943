import React from "react";

import TextField from "@material-ui/core/TextField";
import { useInput, useTranslate } from "react-admin";

const CustomInput = (props) => {
  const {
    input: { name, onChange, source, type },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const translate = useTranslate();

  return (
    <TextField
      className={props.className}
      error={!!(touched && error && error)}
      fullWidth
      helperText={touched && error && translate(error)}
      label={props.label}
      margin={props.margin}
      multiline={props.multiline}
      name={name}
      onChange={onChange}
      placeholder={props.placeholder}
      required={isRequired}
      rows={props.rows}
      source={source}
      type={type}
      variant="outlined"
    />
  );
};

export default CustomInput;
