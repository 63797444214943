import { withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { Fragment } from "react";
import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  ListButton,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { decode } from "js-base64";
import FormTitle from "../../components/FormTitle";
import { FormShow } from "../../styles/FormShow";
import { format, sume } from "../../utils/enums";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const UserShowActions = ({ basePath, role, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const AddNewButton = withStyles(styles)(
  ({ classes, record, label, path, role }) => {
    return (
      <Button
        className={classes.button}
        component={Link}
        to={{
          pathname: `/${path}/create`,
          search: `?personId=${record.person.id}`,
        }}
        label={label}
        {...record}
      >
        <AddCircleOutlineIcon />
      </Button>
    );
  }
);

const UserShow = (props) => {
  const role = decode(sessionStorage.getItem("right")|| "");
  const translate = useTranslate();
  const classes = FormShow();

  return (
    <Fragment>
      <Show {...props} actions={<UserShowActions role={role} />}>
        <TabbedShowLayout className={classes.borderBottom}>
          <Tab label="General" contentClassName={classes.flexParent}>
            <FormTitle
              title="Información personal"
              className={classes.width100}
            />
            <TextField className={classes.width33} source="person.name" />
            <TextField className={classes.width33} source="person.lastname" />
            <DateField
              className={classes.width33}
              source="person.birthday"
              options={{ format: "DD-MM-YYYY" }}
            />
            <SelectField
              source="person.sex"
              choices={[
                { id: "M", name: "Masculino" },
                { id: "F", name: "Femenino" },
              ]}
              className={classes.width33}
            />
            <ReferenceField
              source="userTypeId"
              reference="user-types"
              link={false}
              allowEmpty
              className={classes.width33}
            >
              <TextField source="description" />
            </ReferenceField>
            <ReferenceField
              link={false}
              reference="roles"
              source="roleId"
              className={classes.width33}
            >
              <TextField size="small" source="description" />
            </ReferenceField>
          </Tab>
          <Tab label="Autenticación" path="auth">
            <FormTitle
              title="Información de Autenticación"
              className={classes.width100}
            />
            <AddNewButton
              label="Agregar Autenticación"
              path="auth/signup"
              role={role}
              {...props}
            />
            <ReferenceManyField
              addLabel={false}
              reference="users"
              target="id"
              className={classes.width100}
            >
              <Datagrid>
                <TextField
                  source="auth.email"
                  label={translate("resources.users.fields.email")}
                />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="Socios" path="partners">
            <AddNewButton label="Agregar Socio" path="partners" role={role} />
            <ReferenceManyField
              addLabel={false}
              reference="partners/relations"
              target="id"
              className={classes.width100}
            >
              <Datagrid>
                <TextField label="id" source="user.id" />
                <TextField
                  source="user.person.name"
                  label={translate("resources.users.fields.person.name")}
                />
                <TextField
                  source="user.person.lastname"
                  label={translate("resources.users.fields.person.lastname")}
                />
                <TextField source="user.person.lastname" label="Fuente" />
                <DateField
                  source="user.person.createdOn"
                  label="Fecha de ingreso"
                />
                <TextField source="totalProfit.sonCount" label="Hijos" />
                <FunctionField
                  render={(record) =>
                    format(record.totalProfit.totalSonInvoiced)
                  }
                  label="Venta Hijos"
                />
                <TextField source="totalProfit.grandsonCount" label="Nietos" />
                <FunctionField
                  render={(record) =>
                    format(record.totalProfit.totalGrandsonInvoiced)
                  }
                  label="Venta Nietos"
                />
                <FunctionField
                  render={(record) =>
                    sume(
                      record.totalProfit.totalGrandsonInvoiced,
                      record.totalProfit.totalSonInvoiced
                    )
                  }
                  label="Total ventas"
                />
                <TextField
                  source="user.person.contactEmail"
                  label="Correo electrónico"
                />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Fragment>
  );
};

export default UserShow;
