import React from "react";
import {
  CreateButton,
  Datagrid,
  EditButton,
  EmailField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

import FullNameField from "../../components/FullNameField";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput alwaysOn resettable source="corporateEmail" />
    <ReferenceInput
      alwaysOn
      reference="positions"
      resettable
      source="positionId"
    >
      <SelectInput />
    </ReferenceInput>
  </Filter>
);

const Actions = ({ permissions, basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const EmployeeList = ({ permissions, ...props }) => {
  return (
    <List
      filters={<Filters />}
      sort={{ field: "id", order: "DESC" }}
      actions={<Actions permissions={permissions} />}
      perPage={25}
      {...props}
    >
      <Datagrid>
        <TextField emptyText="-" source="id" />
        <ReferenceField source="personId" reference="persons" link="show">
          <FullNameField />
        </ReferenceField>
        <EmailField source="corporateEmail" />
        <ReferenceField source="positionId" reference="positions" link={false}>
          <TextField emptyText="-" source="name" />
        </ReferenceField>
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default EmployeeList;
