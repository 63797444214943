import React from "react";
import { Sidebar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Suggestions from "../components/Suggestions";

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: "#aa0515",
    height: "100%",
    marginTop: 0,
    paddingTop: "1.5em",
    boxSizing: "border-box",
  },
});

const MySidebar = (props) => {
  const classes = useSidebarStyles();
  return <>
        <Sidebar classes={classes} {...props} />
        <Suggestions />
  </>
};

export default MySidebar;
