import { decode, toBase64 } from "js-base64";
import decodeJwt from "jwt-decode";

export default {
  login: async ({ form }) => {
    const { email, password } = form;
    const request = new Request(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ ...res }) => {
        const decodeToken = decodeJwt(res.access_token);
        const role = toBase64(res.roles.name);        

        sessionStorage.setItem("name", decodeToken.firstName || "Visitante");
        sessionStorage.setItem("lastname", decodeToken.lastName || "Anónimo");
        sessionStorage.setItem("date", "B"+res.access_token+'XWCvJn2WRM');
        sessionStorage.setItem("email", decodeToken.email);
        sessionStorage.setItem("right", role);
        sessionStorage.setItem("s", toBase64(decodeToken.userId));
      });
  },

  logout: () => {
    sessionStorage.clear();
    return Promise.resolve();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401) {
      sessionStorage.removeItem("date");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    return sessionStorage.getItem("date")
      ? Promise.resolve()
      : Promise.reject();
  },

  getPermissions: () => {
    const role =  decode(sessionStorage.getItem("right")|| "");
    return role ? Promise.resolve(role) : Promise.reject();
  },

  changePassword: async ({ form }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_URL}/auth/request-reset-password`,
      {
        method: "POST",
        body: JSON.stringify({ ...form }),
        headers: new Headers({
          "Content-Type": "application/json"
        }),
      }
    );

    return fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  },
  
  resetPassword: async ({ form, token }) => {
    const request = await new Request(
      `${process.env.REACT_APP_API_URL}/auth/reset-password`,
      {
        method: "PATCH",
        body: JSON.stringify({ ...form }),
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    );
    return fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  },
};
