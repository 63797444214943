import React from "react";
import {
  useDataProvider,
  Button,
  useRefresh,
  useNotify,
  useRedirect,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { decode } from "js-base64";


export const useStyles = makeStyles(() => ({
  root: {
    marginLeft: "auto",
    paddingTop: "4px",
    paddingBottom: "4px",
    display: "flex",
  },
}));

export const PaymentButton = ({
  record,
  total,
  totalCommission,
  reduction = 0,
}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirectTo = useRedirect();
  const role = decode(sessionStorage.getItem("right")|| "");

  const handleClick = () => {
    dataProvider
      .create(`profits/${record.id}/pay/commission`, {
        data: { amount: total, reduction },
      })
      .then(() => {
        // success side effects go here
        refresh();
        redirectTo("/profits");
        notify("Se ha realizado la transacción", "info");
      })
      .catch((error) => {
        // failure side effects go here
        notify(`Hubo: ${error.message}`, "warning");
      });
  };

  if (role === "admin") {
    return (
      <Button
        disabled={record.paidOut || totalCommission === 0}
        className={classes.root}
        color="secondary"
        label="Realizar Pago"
        onClick={handleClick}
        variant="contained"
      />
    );
  }

  return null;
};
