import React from "react";
import {
  AutocompleteInput,
  Create,
  FormTab,
  ListButton,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { FormGeneral } from "../../styles/FormGeneral";

const Actions = (props) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} />
  </TopToolbar>
);

const UserCreate = (props) => {
  const translate = useTranslate();
  const classes = FormGeneral();

  const optionRenderer = (people) => {
    if (people) {
      return `${people.name} ${people.lastname}`;
    }
    return [];
  };

  return (
    <Create title="" {...props} actions={<Actions {...props} />}>
      <TabbedForm variant="outlined" redirect="list">
        <FormTab label="Usuarios" contentClassName={classes.root}>
          <ReferenceInput
            source="roleId"
            reference="roles"
            fullWidth
            validate={required()}
            formClassName={classes.width50}
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <ReferenceInput
            source="userTypeId"
            reference="user-types"
            fullWidth
            validate={required()}
            formClassName={classes.width50}
            defaultValue={4}
          >
            <SelectInput optionText="description" disabled />
          </ReferenceInput>
          <ReferenceInput
            source="personId"
            reference="persons"
            label={translate("resources.users.fields.person.name")}
            filterToQuery={(searchText) => ({ name: searchText || " " })}
            fullWidth
            validate={required()}
            formClassName={classes.width50}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              shouldRenderSuggestions={(value) => value.length >= 2}
            />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default UserCreate;
// aortiz+2@statoord.c
