import React, { useState } from "react";
import { required } from "react-admin";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import { makeStyles } from "@material-ui/styles";
import { useNotify } from "ra-core";
import { useHistory } from "react-router-dom";

import statoord from "../../assets/images/logo-statoord.jpg";
import { theme } from "../../theme";
import servicesLogin from "../../Providers/authProvider";
import { updatedPassword, thereWasProblem } from "../../utils/messages";
import CustomForm from "../../components/CustomForm";
import CustomInput from "../../components/CustomInput";
import thanksYou from "../../assets/images/thank-you-page.gif";

const useStyles = makeStyles((theme) => ({
  background: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  saveButton: {
    marginTop: "14px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0 !important",
      order: "1",
      width: "100%",
    },
  },
  canceledButton: {
    color: "#34D597 ",
    marginTop: "17px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      order: "2",
    },
  },
  logo: {
    width: "250px",
  },
  title: {
    color: "#495b67",
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
    },
  },
  text: {
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "center",
  },
  parent: {
    boxShadow: " 0 2px 10px 1px rgba(46,91,255,0.12)",
    marginTop: "124px",
    padding: "0 46px 0 46px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  containerButton: {
    justifyContent: "flex-end ",
    padding: "20px 0 ",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexFlow: "column",
      padding: "20px 0 ",
    },
  },
  footer: {
    marginTop: theme.spacing(5),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const notify = useNotify();
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (form) => {
    servicesLogin
      .changePassword({ form })
      .then(() => {
        notify(updatedPassword, "info", 8000);
        setShowSuccess(true);
      })
      .catch(() => {
        notify(thereWasProblem, "error", 6000);
      });
  };

  const handleClick = () => {
    history.push("/");
  };

  return (
    <Grid justifyContent="center" container className={classes.background}>
      <Grid item xs={12} lg={5}>
        <Card className={classes.parent}>
          <Paper className={classes.paper} elevation={0}>
            <img
              className={classes.logo}
              src={statoord}
              alt="Logo de ibanOnline"
              width="150px"
            />
          </Paper>
          <CardContent>
            {!showSuccess ? (
              <>
                <Typography className={classes.title} variant="h1">
                  Olvidaste tu contraseña?
                </Typography>
                <p className={classes.text}>
                  Escribe tu correo y te enviaremos un enlace para restablecer
                  tu contraseña.
                </p>
                <CustomForm onSubmit={handleSubmit}>
                  <CustomInput
                    label="Correo electrónico"
                    type="text"
                    source="email"
                    validate={required()}
                  />
                  <CardActions
                    classes={{
                      root: classes.containerButton,
                    }}
                  >
                    <Button
                      className={classes.canceledButton}
                      color="primary"
                      onClick={handleClick}
                      size="large"
                    >
                      CANCELAR
                    </Button>
                    <Button
                      className={classes.saveButton}
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      CAMBIAR TU CONTRASEÑA
                    </Button>
                  </CardActions>
                </CustomForm>
              </>
            ) : (
              <div>
                <Typography
                  className={classes.title}
                  variant="h3"
                  align="center"
                >
                  Te hemos enviado un correo, con un link para restablecer tu
                  contraseña.
                </Typography>
                <div style={{ display: "flex", justifyContent: " center" }}>
                  <img src={thanksYou} alt="gracias" width="200" height="200" />
                </div>
              </div>
            )}
          </CardContent>
        </Card>
        <Box component="small">
          <Typography
            align="center"
            className={classes.footer}
            color="textSecondary"
            component="p"
            variant="overline"
          >
            ©StatooRD. Todos los derechos reservados. Version 0.0.1
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const ForgotPasswordWithTheme = (props) => (
  <ThemeProvider theme={createTheme(theme)}>
    <ForgotPassword {...props} />
  </ThemeProvider>
);

export default ForgotPasswordWithTheme;
