import React from "react";
import {
  AutocompleteInput,
  Create,
  DateInput,
  email,
  FormTab,
  ListButton,
  number,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  TopToolbar,
} from "react-admin";

import { FormGeneral } from "../../styles/FormGeneral";

const Actions = (props) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} />
  </TopToolbar>
);

const EmployeeCreate = (props) => {
  const classes = FormGeneral();
  const optionRenderer = (persons) => {
    if (persons) {
      return `${persons.firstName} ${persons.lastName}`;
    }
    return [];
  };

  return (
    <Create title=" " actions={<Actions {...props} />} {...props}>
      <TabbedForm redirect={props.basePath} variant="outlined">
        <FormTab label="Empleado" contentClassName={classes.root}>
          <ReferenceInput
            formClassName={classes.width50}
            fullWidth
            validate={[required()]}
            source="personId"
            reference="persons"
            filterToQuery={(searchText) => ({
              firstName: searchText || " ",
            })}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              shouldRenderSuggestions={(value) => value.length > 2}
            />
          </ReferenceInput>

          <ReferenceInput
            formClassName={classes.width50}
            fullWidth
            reference="countries"
            source="countryId"
            validate={[required()]}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            formClassName={classes.width50}
            fullWidth
            reference="branch-offices"
            source="branchOfficeId"
            validate={[required()]}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            formClassName={classes.width50}
            fullWidth
            reference="positions"
            source="positionId"
            validate={[required()]}
          >
            <SelectInput />
          </ReferenceInput>
          <TextInput
            formClassName={classes.width50}
            fullWidth
            source="corporateEmail"
            validate={[required(), email()]}
          />
          <TextInput
            formClassName={classes.width50}
            fullWidth
            source="phoneNumber"
            validate={number()}
          />
          <DateInput
            formClassName={classes.width50}
            fullWidth
            source="effectiveDate"
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default EmployeeCreate;
