import React from "react";
import {
  Datagrid,
  Filter,
  List,
  ShowButton,
  TextField,
  TextInput,
  EditButton,
  CreateButton,
  TopToolbar,
} from "react-admin";
import FullNameField from "../../components/FullNameField";
import { decode } from "js-base64";


const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput source="id" alwaysOn resettable />
    <TextInput source="name" alwaysOn resettable />
  </Filter>
);

const Actions = ({ role, basePath }) => (
  <TopToolbar>
    {role === "admin" && <CreateButton basePath={basePath} style={{background:'black', color:'white'}} />}
  </TopToolbar>
);
const PersonList = (props) => {
  const role =  decode(sessionStorage.getItem("right")|| "");

  

  return (
    <List
      actions={<Actions role={role} />}
      filters={<Filters />}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: "id", order: "DESC" }}
      perPage={25}
      {...props}
    >
      <Datagrid>
        <TextField emptyText="-" source="id" />
        <FullNameField />
        <TextField emptyText="-" source="sex" />
        <TextField emptyText="-" source="birthday" />
        <TextField emptyText="-" source="contactEmail" />
        <TextField emptyText="-" source="phone" />
        {role === "admin" && <EditButton />}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default PersonList;
