import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";

const monthNames = [
  ["January","Enero"],
  ["February","Febrero"],
  ["March","Marzo"],
  ["April","Abril"],
  ["May","Mayo"],
  ["June","Junio"],
  ["July","Julio"],
  ["August","Agosto"],
  ["September","Septiembre"],
  ["October","Octubre"],
  ["November","Noviembre"],
  ["December","Diciembre"],
];

const Styles = makeStyles(() => ({
  title: {
    fontSize: "31px",
    paddingTop: "1rem",
    textAlign: "center",
    fontWeight: "revert",
  },
  description: {
    padding: "20px",
  },
  background: {
    background: "linear-gradient(to right, #6f7cc8 36%, #3f51b5 100%);",
    color: "white",
  },
  CenterLoading: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  parents: {
    display: "flex",
    paddingTop: "20px",
    fontSize: "20px",
    fontWeight: "600",
  },
  miniBox: {
    backgroundColor: "red",
    width: "20px",
    height: "20px",
    marginRight: "10px",
    marginLeft: "10px",
  },
  secondMiniBox: {
    backgroundColor: "green",
    width: "20px",
    height: "20px",
    marginRight: "10px",
    marginLeft: "36px",
  },
}));

const values = [];

export const handleDates = (statistics) => {

  return statistics?.graphic?.map((item) => {
    values.push(item.totalCommissionsGrandsonRedeemable,item.totalCommissionsSonRedeemable);
    const month = [...monthNames].filter(m=> m[0] == item.month)[0][1];
    return {
      ...item,
      month,
    }

  });
  
};



export default ({
  statisticsData = [],
  userData = null,
  sonAndGrandsons = null,
}) => {
  const classes = Styles();

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Card>
          <div className={classes.parents}>
            <div className={classes.miniBox} />
            Hijos: {sonAndGrandsons?.data?.numberOfSon || 0}
            <div className={classes.secondMiniBox} />
            Nietos: {sonAndGrandsons?.data?.numberOfGrandson || 0}
          </div>
          <CardHeader title="Historial De Ganancias" />
          <CardContent>
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart
                width={500}
                height={250}
                data={handleDates(statisticsData)}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="month" name="month" />
                <YAxis allowDecimals={false} unit="$" tickCount={Math.max(...values)+5} />
                <CartesianGrid strokeDasharray="5 5"  />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="totalCommissionsSonRedeemable"
                  name="Comisiones hijos"
                  stroke="red"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
                <Area
                  type="monotone"
                  name="Comisiones nietos"
                  dataKey="totalCommissionsGrandsonRedeemable"
                  stroke="green"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
