import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import {
  Create,
  FormTab,
  ListButton,
  PasswordInput,
  required,
  TabbedForm,
  TextInput,
  TopToolbar,
} from "react-admin";
import FormTitle from "../../components/FormTitle";
import { FormGeneral } from "../../styles/FormGeneral";
import { fetchWithToken } from "../../utils/fetch";

const Actions = ({ userId }) => (
  <TopToolbar>
    <ListButton to={userId ? `/users/${userId}/show/auth` : ``} />
  </TopToolbar>
);

const AuthenticationCreate = ({ record, ...props }) => {
  const classes = FormGeneral();
  const { personId: userIdString } = parse(props.location.search);
  const userId = userIdString ? parseInt(userIdString, 10) : "";
  const [email, setEmail] = useState([]);

  useEffect(() => {
    fetchWithToken(`/persons/${userId}`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return setEmail(json);
      });
  }, [userId]);

  return (
    <Create
      title=" "
      {...props}
      actions={<Actions userId={userId} {...props} />}
    >
      <TabbedForm
        initialValues={{ userId }}
        redirect="/users"
        variant="outlined"
      >
        <FormTab label="General" contentClassName={classes.root}>
          <FormTitle
            title="Información de Autenticación"
            formClassName={classes.width100}
          />
          <TextInput
            formClassName={classes.width50}
            fullWidth
            initialValue={email.contactEmail}
            source="email"
            disabled
            validate={required()}
          />
          <PasswordInput
            formClassName={classes.width50}
            fullWidth
            source="password"
            validate={required()}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default AuthenticationCreate;
