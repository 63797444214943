import React from "react";
import {
  DateField,
  EditButton,
  EmailField,
  FunctionField,
  ListButton,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";

import FormTitle from "../../components/FormTitle";
import { FormShow } from "../../styles/FormShow";

const Actions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const EmployeeShow = ({ ...props }) => {
  const classes = FormShow();

  return (
    <Show actions={<Actions {...props} />} {...props}>
      <TabbedShowLayout>
        <Tab label="Información" contentClassName={classes.flexParent}>
          <FormTitle title="Compañía" className={classes.width100} />

          <ReferenceField
            className={classes.width25}
            link={false}
            reference="countries"
            source="countryId"
          >
            <TextField emptyText="-" source="name" />
          </ReferenceField>
          <ReferenceField
            className={classes.width25}
            source="branchOfficeId"
            reference="branch-offices"
            link={false}
          >
            <TextField emptyText="-" source="name" />
          </ReferenceField>

          <FormTitle title="Empleado" className={classes.width100} />

          <ReferenceField
            className={classes.width25}
            link="show"
            reference="persons"
            source="personId"
          >
            <FunctionField
              render={(record) => `${record.firstName} ${record.lastName}`}
            />
          </ReferenceField>

          <ReferenceField
            className={classes.width25}
            source="positionId"
            reference="positions"
            link={false}
          >
            <TextField emptyText="-" source="name" />
          </ReferenceField>
          <TextField
            emptyText="-"
            className={classes.width25}
            source="phoneNumber"
          />
          <EmailField
            emptyText="-"
            className={classes.width25}
            source="corporateEmail"
          />
          <DateField className={classes.width25} source="effectiveDate" />
          <DateField
            emptyText="-"
            className={classes.width25}
            source="expirationDate"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
export default EmployeeShow;
