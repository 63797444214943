import React from "react";
import { Notification, required } from "react-admin";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Grid,
} from "@material-ui/core";
import { useNotify } from "ra-core";

import servicesLogin from "../../Providers/authProvider";
import {
  thereWasProblem,
  passwordDoNotMatch,
  updatedPassword,
} from "../../utils/messages";
import CustomInput from "../../components/CustomInput";

import CustomForm from "../../components/CustomForm";
import { minLength } from "../../utils/validators";

const useStyles = makeStyles((theme) => ({
  saveButton: {
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
}));

const ChangePasswordUser = () => {
  const classes = useStyles();
  const notify = useNotify();

  const handleSubmit = (form) => {
    if (form.newPassword !== form.confirmPassword) {
      notify(passwordDoNotMatch, "error", 6000);
    } else {
      servicesLogin
        .changePassword({ form })
        .then(() => {
          notify(updatedPassword, "info");
        })
        .catch(() => {
          notify(thereWasProblem, "warning", 60000);
        });
    }
  };

  return (
    <Card>
      <CardHeader title="Cambio de contraseña" />
      <Divider />
      <CardContent>
        <CustomForm onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <CustomInput
                label="Contraseña actual"
                source="currentPassword"
                type="password"
                validate={[required(), minLength(7)]}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <CustomInput
                label="Nueva contraseña"
                source="newPassword"
                type="password"
                validate={[required(), minLength(7)]}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <CustomInput
                label="Confirmar contraseña"
                source="confirmPassword"
                type="password"
                validate={required()}
              />
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "20px" }} />
          <Button
            className={classes.saveButton}
            color="primary"
            style={{ marginTop: "20px" }}
            type="submit"
            variant="contained"
          >
            Confirmar cambios
          </Button>
        </CustomForm>
      </CardContent>
      <Notification />
    </Card>
  );
};

ChangePasswordUser.propTypes = {
  className: PropTypes.string,
};

export default ChangePasswordUser;
