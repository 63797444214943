import React from "react";
import { useTranslate, Notification, required } from "react-admin";
import { useNotify } from "ra-core";
import {
  Box,
  Button,
  createMuiTheme,
  Grid,
  makeStyles,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { theme } from "../../theme";

import servicesLogin from "../../Providers/authProvider";
import { thereWasProblem } from "../../utils/messages";
import CustomForm from "../../components/CustomForm";
import CustomInput from "../../components/CustomInput";
import { minLength } from "../../utils/validators";
import { FormGeneral } from "../../styles/FormGeneral";
import reset from "../../assets/images/reset-password.jpg";

const useStylesPage = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
  },
  logo: {
    position: "absolute",
    left: "1rem",
    top: "1rem",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    alignItems: "center",
    background: `url(${reset})`,
    backgroundColor: theme.palette.neutral,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    textAlign: "left",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
    textShadow: "#555 1px 1px 1px",
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  contentHeader: {
    alignItems: "center",
    display: "flex",
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  contentBody: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    flexBasis: 700,
    paddingBottom: 125,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(1),
  },
  titleOverline: {
    fontSize: 14,
    paddingBottom: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  footer: {
    marginBottom: theme.spacing(1),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

const ChangePassword = ({ ...props }) => {
  const notify = useNotify();
  const classes = useStylesPage();
  const translate = useTranslate();
  const className = FormGeneral();

  const handleSubmit = (form) => {
    const token = props.match.params.token;

    servicesLogin
      .resetPassword({ form, token })
      .then(() => {
        props.history.push("/login");
      })
      .catch(() => notify(thereWasProblem, "error", 6000));
  };

  return (
    <div className={classes.root}>
      {/* <img
        className={classes.logo}
        loading="auto"
        width="60"
        alt=""
        src={isoTypeWhite}
      /> */}
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={6}>
          <div className={classes.quote}></div>
        </Grid>
        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}></div>
            <div className={classes.contentBody}>
              <div className={className.stylesForm}>
                <CustomForm onSubmit={handleSubmit}>
                  <Typography
                    className={classes.titleOverline}
                    variant="overline"
                  >
                    StatooRD CRM
                  </Typography>
                  <Typography className={classes.title} variant="h2">
                    Cambiar contraseña
                  </Typography>
                  <CustomInput
                    className={classes.textField}
                    label="Nueva Contraseña"
                    source="password"
                    type="password"
                    validate={[required(), minLength(7)]}
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CustomForm>
              </div>
            </div>
            <Notification />
            <Box component="small">
              <Typography
                align="center"
                color="textSecondary"
                variant="overline"
                component="p"
                className={classes.footer}
              >
                © StatooRD. Todos los derechos reservados. Version 0.0.1
              </Typography>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const ChangePassWithTheme = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <ChangePassword {...props} />
  </ThemeProvider>
);

export default ChangePassWithTheme;
