import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { format } from "../../utils/enums";
import { fetchWithToken } from "../../utils/fetch";

const useStyles = makeStyles((theme) => ({
  root: {},
  summaryButton: {
    backgroundColor: theme.palette.common.white,
  },
  barChartIcon: {
    marginRight: theme.spacing(1),
  },
  parents: {
    display: "flex",
    paddingTop: "20px",
    fontSize: "20px",
    fontWeight: "600",
  },
  miniBox: {
    backgroundColor: "red",
    width: "20px",
    height: "20px",
    marginRight: "10px",
    marginLeft: "10px",
  },
  secondMiniBox: {
    backgroundColor: "green",
    width: "20px",
    height: "20px",
    marginRight: "10px",
    marginLeft: "36px",
  },
  cardHeader: {
    height: "3rem",
    background: "black",
  },
  textHeader: {
    color: "white",
    paddingTop: "12px",
    fontWeight: "800",
  },
  cardDetail: {
    background: "white",
    height: "7rem",
    margin: "auto",
  },
  divider: {
    backgroundColor: "#807b7b3d",
    height: "4px",
    marginBottom: " 2rem",
    width: "77rem",
  },
  center: {
    textAlign: "center",
  },
}));

const getGreeting = () => {
  let message = "";
  const today = new Date();
  const curHr = today.getHours();

  switch (true) {
    case curHr >= 0 && curHr < 6:
      message = "Qué haces tan temprano?";
      break;
    case curHr >= 6 && curHr <= 12:
      message = "Buenos Días";
      break;
    case curHr >= 12 && curHr < 19:
      message = "Buenas Tardes";
      break;
    case curHr >= 19 && curHr < 24:
      message = "Buenas Noches";
      break;
    default:
      break;
  }

  return message;
};

function Header() {
  const [statisticsData, setStatisticsData] = useState();

  const classes = useStyles();
  const firstName = sessionStorage.getItem("name") || "Visitante";
  const lastName = sessionStorage.getItem("lastname") || "Anónimo";
  const monthNames = [
    ["January","Enero"],
    ["February","Febrero"],
    ["March","Marzo"],
    ["April","Abril"],
    ["May","Mayo"],
    ["June","Junio"],
    ["July","Julio"],
    ["August","Agosto"],
    ["September","Septiembre"],
    ["October","Octubre"],
    ["November","Noviembre"],
    ["December","Diciembre"],
  ];

  const handleDates = (statistics) => {
    return statistics?.map((item) => {
      const month = [...monthNames].filter(m=> m[0] == item.month)[0][1];
      
      return {
        ...item,
        month,
      }

    });
  };

  useEffect(() => {
    fetchWithToken(`/profits/dashboard/statistics`)
    .then((res) => res.json())
    .then((statistics) => setStatisticsData(statistics?.data));
  }, []);

  return (
    <Grid  spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" gutterBottom variant="overline">
          Dashboard
        </Typography>
        <Typography component="h1" gutterBottom variant="h3">
          {getGreeting()}, {firstName} {lastName}
        </Typography>
      </Grid>
      {statisticsData && (
        <>
          <Grid item sm={12} md={12}>
            <Card>
              <CardHeader title="Historial De Ganancias" />
              <CardContent>
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart
                    width={730}
                    height={250}
                    data={handleDates(statisticsData?.graphicData)}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                    <Line
                      type="monotone"
                      dataKey="totalCommissionsSonRedeemable"
                      stroke="#8884d8"
                    />
                    <Tooltip
                      formatter={(value, name, props) => [
                        format(value),
                        name === "totalCommissionsSonRedeemable"
                          ? "Comisión total 3%"
                          : "Comisión total 1%",
                      ]}
                    />
                    <Line
                      type="monotone"
                      dataKey="totalCommissionsGrandsonRedeemable"
                      stroke="#82ca9d"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md className={classes.center}>
            <div className={classes.cardDetail}>
              <div className={classes.cardHeader}>
                <p className={classes.textHeader}>CANTIDAD DE REFERIDOS</p>
              </div>
              <p>{statisticsData?.partnerCount}</p>
            </div>
            <div className={classes.cardDetail}>
              <div className={classes.cardHeader}>
                <p className={classes.textHeader}>FACTURACIÓN</p>
              </div>
              <p>{format(statisticsData?.totalInvoiced)}</p>
            </div>
            <div className={classes.cardDetail}>
              <div className={classes.cardHeader}>
                <p className={classes.textHeader}>COMISIÓN TOTAL 3%</p>
              </div>
              <p>{format(statisticsData?.threePercent)}</p>
            </div>
            <div className={classes.cardDetail}>
              <div className={classes.cardHeader}>
                <p className={classes.textHeader}>COMISIÓN TOTAL 1%</p>
              </div>
              <p>{format(statisticsData?.onePercent)}</p>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Header;
