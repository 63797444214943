import React from "react";
import { useTranslate, Notification, required } from "react-admin";
import { useNotify } from "ra-core";
import {
  Box,
  Button,
  createMuiTheme,
  Grid,
  makeStyles,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { theme } from "../../theme";
import { decode } from "js-base64";

import logoStatoord from "../../assets/images/logo-statoord.jpg";
import servicesLogin from "../../Providers/authProvider";
import { incorrectUserOrPassword } from "../../utils/messages";
import CustomForm from "../../components/CustomForm";
import CustomInput from "../../components/CustomInput";
import { FormGeneral } from "../../styles/FormGeneral";
import { Link } from "react-router-dom";
import image from "../../assets/images/ecommerce.jpg";

const useStylesPage = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      height: "unset",
      minHeight: "100vh",
    },
  },
  logo: {
    position: "absolute",
    borderRadius: '13px',
    left: "1rem",
    top: "1rem",
    [theme.breakpoints.down("md")]: {
      position: "unset",
      marginTop:'2rem'
    },
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    textAlign: "left",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
    textShadow: "#555 1px 1px 1px",
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      alignItems: "unset",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(1),
  },
  titleOverline: {
    fontSize: 14,
    paddingBottom: theme.spacing(2),
  },
  footer: {
    marginBottom: theme.spacing(1),
    padding:'5px'
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  forgotPassword: {
    textDecoration: "none",
    color: "#3f51b5",
    fontSize: "15px",
    "&:hover": {
      color: "#3f51b5",
    },
  },
}));

const Login = ({ ...props }) => {
  const classes = useStylesPage();
  const notify = useNotify();
  const translate = useTranslate();
  const className = FormGeneral();

  const handleSubmit = (form) => {
    servicesLogin
      .login({ form })
      .then(() => {
        const role =  decode(sessionStorage.getItem("right")|| "");

        setTimeout(() => {
          if (role === "customer") {
            props.history.push("/profits");
          } else {
            props.history.push("/");
          }
        }, 300);
      })
      .catch(() => notify(incorrectUserOrPassword, "error"));
  };

  return (
    <div className={classes.root}>
      <img
        className={classes.logo}
        loading="auto"
        width="150px"
        alt="Logo Statoord"
        src={logoStatoord}
        style={{}}
      />
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={6}>
          <div className={classes.quote} />
        </Grid>
        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}></div>
            <div className={classes.contentBody}>
              <div className={className.stylesForm}>
                <CustomForm onSubmit={handleSubmit}>
                  <Typography
                    className={classes.titleOverline}
                    variant="overline"
                  >
                    StatooRD CRM
                  </Typography>
                  <Typography className={classes.title} variant="h2">
                    Iniciar sesión
                  </Typography>
                  <CustomInput
                    className={classes.textField}
                    label={translate("resources.users.fields.email")}
                    placeholder="email@dominio"
                    source="email"
                    type="text"
                    validate={required()}
                  />
                  <CustomInput
                    className={classes.textField}
                    label={translate("ra.auth.password")}
                    source="password"
                    type="password"
                    validate={[required()]}
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {translate("ra.auth.sign_in")}
                  </Button>
                  <Typography
                    align="right"
                    color="textSecondary"
                    variant="body1"
                  >
                    <Link
                      className={classes.forgotPassword}
                      to="/forgot-password"
                    >
                      ¿Olvidaste tu contraseña?
                    </Link>
                  </Typography>
                </CustomForm>
              </div>
            </div>
            <Notification />
            <Box component="small">
              <Typography
                align="center"
                color="textSecondary"
                variant="overline"
                component="p"
                className={classes.footer}
              >
                © StatooRD CRM. Todos los derechos reservados. Version 1.0.0
              </Typography>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const LoginWithTheme = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
