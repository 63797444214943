import React, { Fragment } from "react";
import {
  DateField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  EditButton,
  TopToolbar,
  ListButton,
} from "react-admin";
import { decode } from "js-base64";


import FormTitle from "../../components/FormTitle";
import { FormShow } from "../../styles/FormShow";

const Actions = ({ role, basePath, data }) => {
  return (
    <TopToolbar>
      {role === "admin" && <EditButton basePath={basePath} record={data} />}
      <ListButton basePath={basePath} />
    </TopToolbar>
  );
};
const PeopleShow = ({ ...props }) => {
  const role = decode(sessionStorage.getItem("right")|| "");
  const classes = FormShow();

  return (
    <Fragment>
      <Show {...props} actions={<Actions role={role} />}>
        <TabbedShowLayout className={classes.borderBottom}>
          <Tab label="General" contentClassName={classes.flexParent}>
            <FormTitle
              title="Información personal"
              className={classes.width100}
            />
            <TextField className={classes.width33} source="name" />
            <TextField className={classes.width33} source="lastname" />
            <TextField className={classes.width33} source="sex" />
            <DateField className={classes.width33} source="birthday" />
            <TextField className={classes.width33} source="contactEmail" />
            <TextField className={classes.width33} source="facebook" />
            <TextField className={classes.width33} source="instagram" />
            <TextField className={classes.width33} source="phone" />
            <TextField className={classes.width33} source="telephone" />
            <TextField className={classes.width33} source="rnc" />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Fragment>
  );
};

export default PeopleShow;
