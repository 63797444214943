import PersonPinIcon from "@material-ui/icons/PersonPin";
import PersonList from "./PersonList";
import PersonCreate from "./PersonCreate";
import PersonEdit from "./PersonEdit";
import PersonShow from "./PersonShow";

export default {
  list: PersonList,
  create: PersonCreate,
  edit: PersonEdit,
  icon: PersonPinIcon,
  show: PersonShow,
};
