import React from "react";
import {
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  DateInput,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Actions = ({ onCancel }) => (
  <TopToolbar>
    <IconButton onClick={onCancel}>
      <CloseIcon />
    </IconButton>
  </TopToolbar>
);

const UserEdit = (props) => {
  return (
    <Edit title="editar usuario" {...props} actions={<Actions {...props} />}>
      <SimpleForm redirect={props.basePath} variant="outlined">
        <ReferenceInput
          source="userTypeId"
          reference="user-types"
          fullWidth
          validate={required()}
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
        <ReferenceInput
          source="roleId"
          reference="roles"
          fullWidth
          validate={required()}
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
