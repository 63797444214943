import React, { useState } from "react";
import { decode } from "js-base64";
import { useSelector } from "react-redux";
import { MenuItemLink, DashboardMenuItem, useTranslate, useLogout } from "react-admin";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import {
  Divider,
  Typography,
  IconButton,
  Avatar,
  Badge,
  useMediaQuery,
  colors,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsIcon from "@material-ui/icons/Settings";
import BallotIcon from "@material-ui/icons/Ballot";
import MoreIcon from "@material-ui/icons/MoreVert";
import DashboardIcon from '@material-ui/icons/Dashboard';

import SubMenu from "./SubMenu";
import { persons, users, profits, transactions } from "../Views";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navigation: {
    "& .MuiListItem-root": {
      padding: theme.spacing(1, 2),
      fontSize: 14,
    },
  },
  itemMenu:{
    color:theme.palette.primary.colorItemMenu
  },
  isActive: {
    color: theme.palette.primary.colorItemMenu,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    borderLeft: `solid ${theme.palette.primary.colorItemMenu} 3px`,
    "& path": {
      fill: theme.palette.primary.colorItemMenu,
    },
  },
  user: {
    position: "absolute",
    bottom: 0,
    boxSizing: "border-box",
    width: 240,
   backgroundColor:'#f1f1f1'
  },
  profile: {
    display: "flex",
    alignItems: "center",
  },
  profileCollapsed: {
    padding: theme.spacing(2),
    transition: "all 0.3s ease-in",
  },
  profileUncollapsed: {
    padding: theme.spacing(1),
    transition: "all 0.3s ease-in",
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
    backgroundColor:'#000000d6'
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: "auto",
    color: colors.blueGrey[200],
  },
}));

export const ConfigurationMenu = React.forwardRef((props, ref) => {
  return (
    <MenuItemLink
      ref={ref}
      to="/configuration/change-password"
      primaryText="Configuración"
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  );
});

const MyMenu = ({ onMenuClick, logout, className, dense, hasDashboard }) => {
  const [state, setState] = useState({
    config: true,
    forms: true,
    profit: true,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useStyles();
  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  useSelector((state) => state.theme); // force render on theme change

  const name = sessionStorage.getItem("name") || "Visitante";
  const lastname = sessionStorage.getItem("lastname") || "Anónimo";
  const rol = decode(sessionStorage.getItem("right")|| "");

  const bye = useLogout();
  if (rol != 'customer' && rol != 'admin' && rol != 'employee') {
    bye()
  }

  return (
    <div>
      <div className={clsx(classes.root, className)}>
        <nav className={classes.navigation}>
          {rol === "customer" && hasDashboard ? null : (
            <DashboardMenuItem
              activeClassName={classes.isActive}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              style={{color:'white'}}
              leftIcon={<DashboardIcon className={classes.itemMenu} />}
              
            />
          )}
          {rol === "customer" ? null : (
            <>
              <MenuItemLink
                className={classes.itemMenu}
                to={`/persons`}
                primaryText={translate("resources.persons.name", {
                  smart_count: 2,
                })}
                activeClassName={classes.isActive}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                leftIcon={<persons.icon className={classes.itemMenu} />}
              />
              <MenuItemLink
                className={classes.itemMenu}
                to={`/users`}
                primaryText={translate("resources.users.name", {
                  smart_count: 2,
                })}
                activeClassName={classes.isActive}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                leftIcon={<users.icon className={classes.itemMenu} />}
              />
            </>
          )}

          <SubMenu
            handleToggle={() => handleToggle("profit")}
            isOpen={state.profit}
            sidebarIsOpen={open}
            name="resources.menu.secondSubMenu.profit"
            icon={<MonetizationOnIcon className={classes.itemMenu} />}
            dense={dense}
            style={{color:'white'}}
          >
            <MenuItemLink
              className={classes.itemMenu}
              to={`/profits`}
              primaryText={translate("resources.profits.name", {
                smart_count: 2,
              })}
              activeClassName={classes.isActive}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              leftIcon={<profits.icon className={classes.itemMenu} />}
            />
            <MenuItemLink
              className={classes.itemMenu}
              to={`/transactions`}
              primaryText={translate("resources.transactions.name", {
                smart_count: 2,
              })}
              activeClassName={classes.isActive}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
              leftIcon={<transactions.icon className={classes.itemMenu} />}
            />
          </SubMenu>
          {rol === "customer" ? null : (
            <SubMenu
              handleToggle={() => handleToggle("config")}
              isOpen={state.config}
              sidebarIsOpen={open}
              name="resources.menu.subMenu.config"
              icon={<ListAltIcon className={classes.itemMenu} />}
              dense={dense}
            >
              <MenuItemLink
                className={classes.itemMenu}
                to={`/settings/maintenances`}
                primaryText={translate("resources.menu.routes.maintenances", {
                  smart_count: 2,
                })}
                activeClassName={classes.isActive}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                leftIcon={<BallotIcon className={classes.itemMenu} />}
              />
            </SubMenu>
          )}
        </nav>

        <div className={clsx(classes.user, className)}>
          <Divider className={classes.divider} />
          <div
            className={clsx(
              classes.profile,
              open ? classes.profileCollapsed : classes.profileUncollapsed
            )}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              classes={{
                dot: classes.badgeDot,
                badge: clsx({
                  [classes.badge]: true,
                  [classes.onlineBadge]: "online",
                }),
              }}
              variant="dot"
            >
              <Avatar alt="Person" className={classes.avatar}>
                {name.charAt(0)}
                {lastname.charAt(0)}
              </Avatar>
            </Badge>
            <React.Fragment>
              <div className={classes.details}>
                <Typography variant="h5">
                  {name} {lastname}
                </Typography>
                <Typography variant="subtitle2">En linea</Typography>
              </div>
              <IconButton className={classes.moreButton} size="small">
                <MoreIcon />
              </IconButton>
            </React.Fragment>
          </div>
        </div>
      </div>
      {isXSmall && logout}
    </div>
  );
};

export default withRouter(MyMenu);
